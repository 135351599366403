
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { ElForm } from 'element-ui/types/form'
import ZButton from '@/components/base/z-button.vue'
import { isWechat } from '@/utils/utils'

@Component({
	components: { ZButton }
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
		audio: HTMLAudioElement
		audioScaned: HTMLAudioElement
	}
	private isWechat = false
	private lockShelf = false

	private isLoading = false
	private codesList: any = []
	private searchForm: any = {
		package_attr_num: '',
		shelf_code: ''
	}
	private validateForm = {
		package_attr_num: [
			{ required: true, message: '请扫描包裹号', trigger: 'blur' }
		],
		shelf_code: [{ required: true, message: '请扫描货架', trigger: 'blur' }]
	}
	async created() {
		this.isWechat = isWechat()
	}
	async initJSSdk() {
		if (!this.isWechat) return false
		const url = location.href.split('#')[0]
		const { data } = await this.$axios.post('v1/jobline/js-sdk-config', {
			url
		})
		this.$wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			...data,
			jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
		})
	}
	async scanBarcode() {
		if (!this.$wx) return false
		await this.initJSSdk()
		this.$wx.ready(() => {
			this.$wx.scanQRCode({
				needResult: 1,
				scanType: ['barCode'],
				success: (res: any) => {
					const result = res.resultStr.split(',')
					const code = result[result.length - 1]
					if (/^GLP\d+/.test(code)) {
						this.searchForm.package_attr_num = code
					} else if (/[a-zA-Z]-\d+-\d+$/.test(code)) {
						this.searchForm.shelf_code = code
					}
					this.inShelfPackage()
				}
			})
		})
	}
	async inShelfPackage() {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return false
		const { package_attr_num, shelf_code } = this.searchForm
		if (!package_attr_num || !shelf_code) return
		const { data } = await this.$axios
			.post('/v1/jobline/package_attr_shelf/in', {
				package_attr_num,
				shelf_code
			})
			.catch(e => {
				this.searchForm.package_attr_num = ''
				if (!this.lockShelf) {
					this.searchForm.shelf_code = ''
				}
				this.$refs.audioScaned.play()
				return {
					data: null
				}
			})
		if (!data) return
		this.codesList.unshift({
			...this.searchForm
		})
		this.searchForm.package_attr_num = ''
		if (!this.lockShelf) {
			this.searchForm.shelf_code = ''
		}
		this.$message.success('上架成功')
		this.$refs.audio.play()
	}
}
